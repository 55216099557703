<template lang="html">
  <div>
    <div class="row">
      <div class="col-8">
        <div class="card">
          <div class="card-body form-card">

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Tiêu đề</label>
              </div>
              <div class="col-10">
                <el-input
                  placeholder="Tên"
                  v-model="form.name"
                  :class="errors.has('name') ? 'border-danger' : ''"
                  v-validate="'required'"
                  data-vv-name="name"
                  data-vv-as="Tên"
                ></el-input>
                <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Đường dẫn</label>
              </div>
              <div class="col-10">
                <el-input
                  placeholder="Đường dẫn"
                  v-model="form.slug"
                  :class="errors.has('slug') ? 'border-danger' : ''"
                  v-validate="'required'"
                  data-vv-name="slug"
                  data-vv-as="Đường dẫn"
                ></el-input>
                <span class="text-danger" v-if="errors.has('slug')">{{ errors.first('slug') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Giá/tháng</label>
              </div>
              <div class="col-10">
                <el-input
                  type="number"
                  placeholder="Giá"
                  v-model="form.price"
                  :class="errors.has('price')?'border-danger':''"
                  v-validate="'required|min_value:0'"
                  data-vv-name="price"
                  data-vv-as="Giá"
                ></el-input>
                <span class="text-danger" v-if="errors.has('price')">{{ errors.first('price') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Loại</label>
              </div>
              <div class="col-10">
                <el-select v-model="form.type" placeholder="Loại" class="full-width">
                  <el-option label="Gói" value="package" :disabled="true"></el-option>
                  <el-option label="Tùy chỉnh" value="custom"></el-option>
                </el-select>
              </div>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body form-card">

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Thời gian (tháng)</label>
              </div>
              <div class="col-10">
                <el-select v-model="timeSelected" multiple placeholder="Chọn thời gian" class="full-width">
                  <el-option
                    v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group row" v-if="timeSelected && timeSelected.length">
              <div class="col-2">
                <label>Giảm giá</label>
              </div>
              <div class="col-10">
                <div class="custom-form-control" v-for="item in timeSelected">
                  <div class="col-3">
                    <span>{{ item }} tháng</span>
                  </div>
                  <div class="col-9">
                    <el-input placeholder="Giảm giá (nếu có)" v-model="listTimesDiscounts[item]">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body form-card">
            <div class="row">
              <div class="col-2">
                <label class="required">Số người</label>
              </div>
              <div class="col-10">
                <el-select
                  v-model="userSelected"
                  multiple
                  placeholder="Nhập số người tham gia"
                  class="full-width"
                  allow-create
                  filterable
                  default-first-option
                >
                  <el-option
                    v-for="item in [50, 100, 200, 300, 500, 800, 1000, 2000, 5000, 10000, 20000]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row" v-if="userSelected && userSelected.length">
              <div class="col-2">
                <b class="required">Giá tiền</b>
              </div>
              <div class="col-10">
                <div class="custom-form-control" v-for="item in userSelected">
                  <div class="col-3">
                    <span>{{ item }} người</span>
                  </div>
                  <div class="col-9">
                    <el-input placeholder="Số tiền" v-model="listUserPrices[item]">
                      <template slot="append">đ</template>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body form-card">
            <label>Hình ảnh</label>
            <my-image v-model="form.image"></my-image>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body form-card">
            <label>Mô tả</label>
            <div class="row">
              <div class="col-sm-12">
                <editor
                  apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
                  :height="10000"
                  v-model="form.content">
                </editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="page-action">
          <el-button @click="cancel">Quay lại</el-button>
          <el-button type="primary" @click="save">Lưu lại</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MessageBox, Message, Input, Upload, Switch, Button, Select, Option } from 'element-ui';
  import MyImage from 'src/components/UIComponents/Image'
  import Editor from '@tinymce/tinymce-vue'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      ElUpload: Upload,
      ElSwitch: Switch,
      ElSelect: Select,
      ElOption: Option,
      Editor,
      MyImage,
      Message
    },
    data() {
      return {
        form: {
          type: 'custom'
        },
        timeSelected: [1],
        listTimesDiscounts: {
          1: 0
        },
        userSelected: [],
        listUserPrices: {}
      }
    },

    mounted() {
      this.$store.dispatch('setPageTitle', 'Tạo gói');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllPackageEnterprises'});
      },
      save() {
        let self = this;
        let data = Object.assign({}, this.form);
        data.number_users = 0;
        data.number_times = 0;
        data.time_discounts = this.listTimesDiscounts;
        data.user_prices = this.listUserPrices;

        this.$validator.validateAll().then(result => {
          if (!result) return;

          if (Object.keys(data.user_prices).length == 0) {
            return Message({ message: 'Chưa chọn số người tham gia', type: 'error' });
          }

          self.$store.dispatch('storeEnterprisePackage', data).then(res => {

            if (!res.success) {
              return Message({ message: res.message, type: 'error' });
            }

            Message({message: 'Tạo mới thành công', type: 'success'});
            setTimeout(() => {
              self.$router.push('/enterprise/packages/' + res.data.id);
            }, 1500);

          }, err => {
            Message({
              dangerouslyUseHTMLString: true,
              message: err,
              type: 'error'
            });
          });
        })
      },
    },
    watch: {
      'form.name': function(val) {
        this.form.slug = this.$util.createHandle(val);
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
