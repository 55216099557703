<template lang="html">
  <div class="image-uploader">
    <div tabindex="0" class="el-upload el-upload--text">
      <label>
        <img v-if="imageUrl" :src="imageUrl">
        <i v-else class="el-icon-plus image-uploader-icon"></i>
        <input type="file" accept="image/*" class="el-upload__input" @change="changeAvatar">
      </label>
    </div>
    <toast position="se"></toast>
  </div>
</template>

<script>

  import { Toast } from 'vuex-toast'
  import { mapActions } from 'vuex'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'

  export default {
    props: ['value'],
    components: {
      Toast
    },
    data() {
      return {
        imageUrl: ''
      }
    },
    methods: {
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      toastr(text, type) {
        this.addToast({
          text,
          type: type,
          dismissAfter: 3000
        })
      },

      changeAvatar(file) {
        var self = this;
        let img = file.currentTarget.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
          this.$emit('input', this.imageUrl);
        }
        reader.readAsDataURL(img);
      },

      handleUploadImage(event) {
        let file = event.target.files[0];
        let types = ['image/jpeg', 'image/png'];
        if (types.indexOf(file.type) == -1) {
          this.toastr("Không đúng định dạng ảnh", "danger");
          return false;
        }

        let formData = new FormData();
        formData.append('upload', file, file.name);

        this.$store.dispatch('uploadImage', formData).then(res => {
          if (res.success) {
            this.imageUrl = res.links[0];
            this.$emit('input', this.image);
          }
        });
      },
    },
    watch: {
      value: {
        immediate: true,
        handler (val, oldVal) {
          this.imageUrl = val;
        }
      }
    }
  }
</script>
