var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-uploader" },
    [
      _c(
        "div",
        { staticClass: "el-upload el-upload--text", attrs: { tabindex: "0" } },
        [
          _c("label", [
            _vm.imageUrl
              ? _c("img", { attrs: { src: _vm.imageUrl } })
              : _c("i", { staticClass: "el-icon-plus image-uploader-icon" }),
            _c("input", {
              staticClass: "el-upload__input",
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.changeAvatar },
            }),
          ]),
        ]
      ),
      _c("toast", { attrs: { position: "se" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }